.footer {
  * {
    direction: ltr !important;
    color: $Grey;
  }
  display: flex;
  justify-content: center;
  padding: 10px;
  .version,
  .copyright {
    a {
      text-decoration: none;
      &:hover {
        color: $blue;
      }
    }
    padding: 0 5px;
  }
}
