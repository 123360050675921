.formalReportModal {
  min-height: 70vh;
  .jodit-container {
    height: 100% !important;
    .jodit-workplace {
      flex-basis: max-content !important;
    }
    .jodit-status-bar {
      display: none !important;
    }
  }
  .oldReportBtn {
    border: none;
    background-color: $blue;
    border-radius: 8px;
    box-shadow: 0 0 6px rgb(0 0 0 / 6%);
    color: $white;
    padding: 4px 10px;
    margin-bottom: 20px;
    width: 100%;
  }
}
