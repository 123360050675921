.expand-element {
  width: 24px;
  height: 24px;
  background-color: #00a1ed;
  position: relative;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  transition: 0.3s;
}
.expand-element:hover {
  transform: scale(0.95);
}

.expand-item {
  position: absolute;
  top: 11px;
  left: 5px;
  background-color: white;
  width: 14px;
  height: 2px;
  transition: 0.3s;
}
.expand-item.expanded {
  transform: rotate(90deg);
}
