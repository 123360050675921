.profile {
  .ant-upload {
    border: 1px solid $whiteE !important;
    border-radius: 50% !important;
    width: 200px !important;
    height: 200px !important;
    margin: 0 auto !important;
    overflow: hidden;
    display: block;
    @media (max-width: 992px) {
      margin-bottom: 30px !important;
    }
  }
  .ant-form-item {
    .ant-form-item-label {
      // text-align: right;
      font-family: "Light";
      color: $Grey;
      // margin-bottom: 10px;
      label {
        font-family: "Light";
        opacity: 0.8;
      }
      .ant-form-item-required {
        font-family: "Light";
        color: $Grey;
        opacity: 1;
        &::before {
          content: "";
        }
      }
    }
  }
  .ant-form-item-control-input {
    border: none !important;
    box-shadow: none !important;
    .ant-form-item-control-input-content {
      input {
        border: none !important;
        box-shadow: none !important;
        border-radius: 10px;
        padding: 8px 20px;
        &::placeholder {
          color: rgba($color: $Grey, $alpha: 0.5);
        }
      }
      .ant-input-affix-wrapper {
        border: none;
        padding: 0 10px !important;
        border-radius: 10px;
        &:focus {
          box-shadow: none !important;
          border: none !important;
        }
      }
    }
  }
  .title {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .buttonGroup {
    display: flex;
    justify-content: space-between;
    .updateBtn {
      border: none;
      background-color: $blue;
      border-radius: 8px;
      border: 1.5px solid $white;
      box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.06);
      color: $white;
      padding: 4px 10px;
      font-family: "Light";

      span {
        margin: 0 5px;
      }
      &.red {
        background-color: $red;
      }
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-left: 7px;
      }
    }
    .deleteUser {
      border: none;
      background-color: $red;
      border-radius: 8px;
      border: 1.5px solid $white;
      box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.06);
      color: $white;
      padding: 4px 10px;
      font-family: "Light";
      span {
        margin: 0 5px;
      }
    }
  }
  .SpinCon {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pointsCon {
    padding: 5vh 20%;
    .label {
      text-align: center;
      font-size: 20px;
    }
    .value {
      padding: 5px 13px;
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-size: 20px;
      width: fit-content;
      margin: 10px auto 0;
    }
  }
  .contactUs {
    width: fit-content;
    .contacts {
      display: flex;
      .contactlink {
        padding: 0 20px;
        a {
          text-decoration: none;
          color: $blue;
          transition-duration: 0.5s;
          &:hover {
            color: rgba($color: $blue, $alpha: 0.7);
          }
        }
      }
    }
  }
}
