.modal-header {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: $blue;
  width: 100%;
  text-align: center;
  padding: 6px 0;
  .title {
    color: white;
    margin: 0;
  }
  .close-icon {
    position: absolute;
    top: 5px;
    left: 15px;
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
    transition: 0.3s all ease-in-out;
    &:hover {
      color: #e9e9e9;
      transform: scale(0.9);
    }
  }
}
