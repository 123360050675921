.cp-layout {
  display: flex;
  .cp-menu {
    &:not(:has(.ant-menu-inline-collapsed)) {
      flex: 256px 0 0;
    }
    .ant-menu {
      &-item {
        &-icon {
          font-size: 20px;
        }
      }
    }
  }
  .cp-main {
    flex: 1 1 auto;
  }
}

.ant-menu-inline-collapsed-tooltip {
  .ant-tooltip-inner {
    color: $white !important;
  }
}
