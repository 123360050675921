.notfound {
  a {
    text-decoration: none;
  }
  .historyBtn {
    color: $blue_2;
    cursor: pointer;
    font-family: "Light";
    display: "flex";
    align-items: "center";
    gap: 3px;

    @media (max-width: 992px) {
      display: block;
      margin-bottom: 10px;
    }
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin-left: 7px;
    }
  }
}
