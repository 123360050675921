.ant-notification {
  margin-inline: 24px 0;
  &[class*="Left"] {
    margin-inline: 0 24px;
  }
  
  .ant-notification-notice {
    border-radius: 20px;
    padding: 20px;
    min-height: 65px;
    img{
      height: 2rem;
    }
    .ant-notification-notice-description {
      font-family: "Light";
      font-size: 14px;
    }
    
  }
}
