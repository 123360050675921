.technical {
  .historyBtn {
    color: $blue_2;
    cursor: pointer;
    font-family: "Light";
    display: "flex";
    align-items: "center";
    gap: 3px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin-left: 7px;
    }
  }
  .techInfo {
    .techImg {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 0 auto 15px;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        background-color: $gray_2;
        border-radius: 50%;
      }
    }
    .title {
      color: $Grey;
      font-family: "Light";
      text-align: center;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .jobTitle {
      color: rgba($color: $Grey, $alpha: 0.5);
      font-family: "Light";
      text-align: center;
      font-size: 13px;
      margin-bottom: 30px;
    }
    .rateCon {
      margin-top: 10px;
      text-align: center;
    }
    .ant-rate {
      width: fit-content;
      display: block;
      pointer-events: none;
      margin: 0 auto;
      margin-bottom: 20px;
      .ant-rate-star {
        font-size: 26px;
        &:not(:last-child) {
          margin-left: 8px;
          margin-right: 0;
        }

        &.ant-rate-star-zero {
          * {
            color: rgba($color: $Grey, $alpha: 0.3) !important;
          }
        }
      }
    }
    .infos {
      padding: 0 8%;
      margin-bottom: 30px;
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        &.col {
          flex-direction: column;
          align-items: flex-start;
        }
        .label {
          color: $blue;
        }
        .value {
          color: $gray47;
          font-family: "Light";
          &.en {
            direction: ltr;
          }
        }
      }
    }
  }
  .ratingCon {
    .title {
      font-family: Light;
      margin-bottom: 20px;
      color: $Grey;
      font-size: 16px;
    }
    .ratingCartCon {
      .ratingCard {
        padding: 15px;
        border-radius: 15px;
        background-color: $whiteFC;
        box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.1);
        margin-bottom: 20px;
        .rateInfo {
          color: $gray47 !important;
          div {
            margin-bottom: 7px;
            font-family: "light";
            font-size: 14px;
            &:last-child {
              margin-bottom: 0;
            }
            span {
              font-family: "Light";
            }
          }
        }
      }
    }
  }

  .techTicks {
    .title {
      font-family: Light;
      margin-bottom: 20px;
      color: $white;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        font-size: 12px;
        margin-bottom: 5px;
        white-space: nowrap;
      }
      .changeStatusSelect {
        &.ant-select-single {
          border: none !important;
          box-shadow: none !important;
          width: 120px;
          margin-right: 10px;
          @media (max-width: 992px) {
            width: 100%;
            margin-left: 0;
            margin-bottom: 10px;
          }
          .ant-select-selector {
            border: none !important;
            box-shadow: none !important;
            border-radius: 10px;
            padding: 2px 20px;
            height: auto;
            background-color: $blue_2;
            color: $white;

            .ant-select-selection-search {
              color: $white;

              input {
                color: $white;
                font-size: 12px;
              }
            }
            .ant-select-selection-item {
              color: $white;
              font-size: 12px;
            }
          }
          &.status1 {
            .ant-select-selector {
              background-color: $pending;
            }
          }
          &.status9 {
            .ant-select-selector {
              background-color: $priced;
            }
          }
          &.status10 {
            .ant-select-selector {
              background-color: $paid;
            }
          }
          &.status11 {
            .ant-select-selector {
              background-color: $partial_paid;
            }
          }
          &.status2 {
            .ant-select-selector {
              background-color: $active;
            }
          }
          &.status3 {
            .ant-select-selector {
              background-color: $done;
            }
          }
          &.status4 {
            .ant-select-selector {
              background-color: $approved;
            }
          }
          &.status5 {
            .ant-select-selector {
              background-color: $denied;
            }
          }
        }
      }
    }
    .tickets_count {
      background-color: $white;
      padding: 10px;
      border-radius: 10px;
      color: $black;
    }
    .ticketsTable {
      position: relative;
      .ant-table-content,
      .ant-table {
        background-color: transparent !important;
        .ant-table-row {
          &:hover {
            background-color: transparent;
            .ant-table-cell {
              background-color: transparent;
            }
          }
        }
      }
      .ticketImg {
        border-radius: 8px;
        width: 45px;
        height: 45px;
        background-color: $gray_2;
        display: block;
        margin-inline: auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .statusBtn {
        border-radius: 15px;
        padding: 3px 2px;
        color: $white;
        margin: 0 auto;
        width: 100%;
        font-size: 11px;
        font-family: Light;
        &.status1 {
          background-color: $pending;
        }
        &.status9 {
          background-color: $priced;
        }
        &.status10 {
          background-color: $paid;
        }
        &.status11 {
          background-color: $partial_paid;
        }
        &.status2 {
          background-color: $active;
        }
        &.status3 {
          background-color: $done;
        }
        &.status4 {
          background-color: $approved;
        }
        &.status5 {
          background-color: $denied;
        }
      }
      .ant-table-cell {
        text-align: center;
        background-color: transparent;
        border: none !important;
        font-size: 11px;
        color: $white;
        padding: 10px 3px;
        &::before {
          display: none;
        }
      }
      .ant-table-thead {
        .ant-table-cell {
          background-color: $gray6A !important;
          overflow: hidden !important;

          &:last-child {
            @media (max-width: 992px) {
              border-radius: 12px;
            }
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          font-family: Light;
        }
      }
    }
    .ratingStars {
      .starsLabel {
        font-size: 12px;
        font-family: Light;
        text-align: center;
        color: rgba($color: $black, $alpha: 0.5);
      }
      .ant-rate {
        width: fit-content;
        display: block;
        pointer-events: none;
        margin: 0 auto;
        white-space: nowrap;
        .ant-rate-star {
          font-size: 16px;
          &:not(:last-child) {
            margin-left: 6px;
            margin-right: 0;
          }

          &.ant-rate-star-zero {
            * {
              color: rgba($color: $white, $alpha: 0.3) !important;
            }
          }
          &.ant-rate-star-full {
            color: $blue !important;
          }
        }
      }
    }
  }
}
