.loadingCon{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    .ant-spin-lg .ant-spin-dot {
        font-size: 50px !important;
    }
}