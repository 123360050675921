.phoneNumberInput {
  padding: 5px 20px;
  direction: ltr;
  border-radius: 25px;
  border: 1px solid $grayD;
  background-color: $white;
  input.ant-input:-webkit-autofill,
  input.ant-input:-webkit-autofill:hover,
  input.ant-input:-webkit-autofill:focus,
  input.ant-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
  }
  transition: all 0.2s;
  .ant-input-wrapper {
    direction: ltr;
  }
  .ant-input-group-addon {
    color: $black;
  }
  &:hover,
  &:focus-within {
    border-color: $blue;
  }
  &.ant-input-group-wrapper-status-error {
    border-color: #ff4d4f;
  }

  input {
    direction: ltr;
    &,
    &:focus,
    &:hover {
      border: none;
      box-shadow: none !important;
    }
  }
  .ant-input-group-addon {
    direction: ltr;
    background-color: transparent;
    border: none;
  }
  &Country {
    &.antd-country-phone-input {
      text-align: left;
      padding: 0;
      &.ant-input-group-wrapper {
        overflow: hidden;
        background-color: $white;
        border-radius: 10px;
        .ant-input-group {
          padding: 0;
          border: 1px solid $whiteD9;
          border-radius: 10px;
          transition-duration: 0.2s;
          &:hover,
          &:focus-within {
            border-color: $blue;
          }
          .ant-input {
            border: none;
            background-color: transparent;
          }
          &-addon {
            border: none;
          }
          &:has([class*="-disabled"]) {
            &:hover,
            &:focus-within {
              border-color: $whiteD9;
            }
          }
        }
        &:has([class*="-disabled"]) {
          background-color: rgba($color: $black, $alpha: 0.04) !important;
          [class*="-disabled"] {
            border-radius: 0 !important;
          }
        }
      }
      &.ant-input-group-wrapper-status-error {
        .ant-input-group {
          border-color: #ff4d4f;
          &:hover,
          &:focus-within {
            border-color: #ffa39e;
          }
        }
      }
    }
  }
}
.passwordInput {
  border-radius: 25px;
  padding: 8px 20px;
  box-shadow: none;
  direction: ltr;
  input.ant-input {
    background-color: $white;
  }
  input.ant-input:-webkit-autofill,
  input.ant-input:-webkit-autofill:hover,
  input.ant-input:-webkit-autofill:focus,
  input.ant-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
  }
  * {
    direction: ltr;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}
.textInput {
  border-radius: 25px;
  padding: 8px 20px;
  box-shadow: none;
  input.ant-input {
    background-color: $white;
  }
  input.ant-input:-webkit-autofill,
  input.ant-input:-webkit-autofill:hover,
  input.ant-input:-webkit-autofill:focus,
  input.ant-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}
.submitButton {
  padding: 8px 20px;
  height: auto;
  width: 100% !important;
}
.historyBtn {
  color: $blue_2;
  cursor: pointer;
  font-family: "Light";
  display: "flex";
  align-items: "center";
  gap: 3px;

  @media (max-width: 992px) {
    display: block;
  }
  margin-bottom: 10px;
  img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    margin-left: 7px;
  }
}

.OTPInput {
  input {
    min-width: 2.8rem;
    min-height: 2.8rem;
    margin: 0 0.2rem;
    font-size: 1.8rem;
    border-radius: 10px;
    border: 1px solid $grayD;
    color: $black;
    transition: all 0.2s;
    &:hover,
    &:focus-visible,
    &:focus {
      border-color: $blue;
      outline: none;
    }
    line-height: normal;
  }
}
.locationInput {
  padding: 0;
  direction: ltr;
  border-radius: 10px;
  input {
    padding-left: 0;
  }
  .locationBtn {
    background-color: $blue;
    border: none;
    color: $blue;
    cursor: pointer;
    font-family: "Light";
    border-radius: 10px;
    color: $white;
    width: 35px;
    height: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.5px;
    .anticon-aim {
      width: 60%;
      height: 60%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.filter-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
}
