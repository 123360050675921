@import "../../styles/abstracts/variables";
.active-cr {
  background-color: #e0e0e052;
  width: 90%;
}

.tickets .ticketsTable .ant-table {
  min-height: auto !important;
}

.ticketsTable {
  position: relative;
  min-height: 60vh;
}
.expanded-row {
  background-color: $whiteE4;
}
