.contactsModal {
  .ant-modal-content {
    background-color: $lightGrey;
    * {
      direction: rtl;
      text-align: right;
    }
  }
  .ant-modal-body {
    padding: 10px 24px;
  }
  .ant-tabs-nav-list {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: none !important;
    .ant-tabs-tab {
      margin: 0;
    }
    .ant-tabs-ink-bar {
      height: 4px;
      border-radius: 10px;
      background-color: $blue;
    }
    .ant-tabs-tab-btn {
      text-shadow: none !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $blue;
    }
  }
  .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  .ant-select-selector {
    background-color: transparent !important;
    margin-bottom: 5px;
  }
  .ant-select-arrow {
    left: 11px;
    right: auto !important;
    transform: translateY(-25%);
  }
  .ant-select-selection-item {
    padding-right: 0 !important;
    padding-left: 18px !important;
  }
  .dynamic-delete-button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .ant-form-item {
    margin-bottom: 3px;
  }
  .addContactBtn {
    position: absolute;
    bottom: -55px;
    left: 0;
    display: flex;
    .save,
    .add {
      display: flex;
      align-items: center;
      background-color: $blue;
      border-radius: 8px;
      border: 1.5px solid $white;
      box-shadow: 0 0 6px rgb(0 0 0 / 6%);
      color: $white;
      margin: 0 5px;
      padding: 4px 10px;
      font-family: "Light";
      width: fit-content;
      cursor: pointer;
      .anticon {
        margin-left: 10px;
        margin-top: 10px;
      }
    }
  }
  .selectContactBtn {
    position: relative;
    transform: translateY(-39px);
    background-color: $blue;
    border-radius: 8px;
    border: 1.5px solid $white;
    box-shadow: 0 0 6px rgb(0 0 0 / 6%);
    color: $white;
    padding: 18px 10px;
    font-family: "Light";
    width: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 1.2rem;
      margin: 0 auto;
      object-fit: contain;
    }
  }
  .addOneContactBtn {
    .anticon {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  .ant-tabs {
    overflow: initial;
  }
  .userbtn {
    position: absolute;
    left: 0;
    top: -5px;
    font-size: 14px;
    z-index: 15;
    .editUser {
      cursor: pointer;
      color: $blue;
    }
    .deleteUser {
      cursor: pointer;
      color: $red;
    }
  }
}
