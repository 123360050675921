.loaderProgress {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: absolute;
  z-index: 1;
  background: transparent;
  overflow: hidden;
  &::after {
    content: "";
    width: 192px;
    height: 4.8px;
    background: $blue;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 1.5s linear infinite;
  }
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
