.techsMapContainer {
  .techsList {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 70px;
    background-color: white;
    width: 320px;
    overflow-y: scroll;
    height: calc(100% - 50px);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    opacity: 0;
    transform: translate(50%, -50%);
    transition: 0.3s;
    visibility: hidden;
    .title {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: rgba(0, 0, 0, 0.548);
      }
    }
    &.open {
      opacity: 1;
      transform: translate(0, -50%);
      visibility: visible;
    }
    .disabled {
      background-color: rgba(128, 128, 128, 0.377) !important;
    }
  }
  .toggle {
    position: absolute;
    top: 60px;
    right: 15px;
    background-color: $white;
    padding: 10px;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    color: #222;
    cursor: pointer;
    z-index: 9999999;
  }

  .colorChangeAnimation {
    animation: color_change 2s ease-in-out both infinite;
  }
  .card {
    .cardHeader {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      .details-btn {
        border: none;
        background-color: transparent;
        color: $blue;
        cursor: pointer;
        font-size: 11px;
      }
    }
    .cardInfo {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-bottom: 2px;
      &.date {
        flex-direction: row-reverse;
      }
    }
  }
}

@keyframes color_change {
  0% {
    color: black;
  }
  50% {
    color: green;
  }

  100% {
    color: black;
  }
}
