.companies {
  .container {
    display: flex;
    flex-direction: column;
  }
  .company,
  .branch {
    padding: 20px;
    .item {
      .title {
        margin: 10px 0;
        font-weight: bold;
        &::after {
          content: ":";
        }
        display: inline;
      }
      .content {
        display: inline;
        box-sizing: content-box;
        height: 1rem;
        padding: 0 20px;
        border-radius: 10px;
      }
    }
  }

  .greyBoxTitle {
    text-align: center;
    margin-bottom: 1rem;

    span {
      color: $blue;
      font-family: "Light";
      font-size: 16px;
      margin: 0 3px;
      &.id {
        font-family: "Medium";
      }
    }
  }

  .ant-form-item {
    .ant-select-single,
    .ant-select-multiple {
      .ant-select-selector {
        box-shadow: none !important;
        border-radius: 10px;
        padding: 5px 20px;
        height: auto;
        color: rgba($color: $black, $alpha: 0.6);
      }
    }
  }
  .ant-form-item {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        input {
          box-shadow: none !important;
          border-radius: 10px;
          padding: 8px 20px;
        }
      }
    }
  }
}
