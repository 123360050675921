@media (prefers-color-scheme: null) {
  body {
    background-color: $dark_Grey;
    .login,
    .register,
    .forgetPassword {
      .registerFormCon {
        background-color: rgba($dark_lightGrey, 0.7);
      }
    }
  }
}
