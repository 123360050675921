.login {
  * {
    font-family: "Medium";
  }
  .FormCon {
    img{
      // height: 5rem;
    }
    border-radius: 30px;
    background-color: rgba($color: $white, $alpha: 0.7);
    padding: 40px 20px;
    .forgetLink {
      font-size: 13px;
      color: $blue;
      cursor: pointer;
    }
  }
}
