.greenBox {
    background-color: #f0fff3;
    height: 100%;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.1);
    overflow: hidden;
    position: inherit;
    * {
      font-family: "Light";
    }
    @media (max-width: 992px) {
      padding: 20px 10px;
    }
    &.readonly {
      .ant-upload-select {
        display: none !important;
      }
    }
  }
  