.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 18px;
  .overlay-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
