.toolTip {
  .ant-tooltip-inner {
    border-radius: 8px;
    padding: 10px !important;
    font-size: 13px;
  }
}

.ant-select-dropdown {
  * {
    font-family: "Medium";
  }
}
