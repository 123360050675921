.ant-pagination {
  margin-top: 20px;
  display: flex;
  &.dark {
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background-color: $gray5;
      svg {
        color: white;
      }
    }

    .ant-pagination-item {
      background: $gray5;
      a {
        color: $white;
      }
    }
    .ant-pagination-item-active {
      background: $gray5;
      a {
        color: $blue_3;
      }
    }
  }
}
