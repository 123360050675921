@import "abstracts/variables";
@import "abstracts/darkVariables";
@import "abstracts/fonts";

@import "layout/header";
@import "layout/layout";
@import "layout/footer";

@import "components/whiteBox";
@import "components/greyBox";
@import "components/greenBox";
@import "components/loading";
@import "components/pagination";
@import "components/notificaton";
@import "components/modalMap";
@import "components/formalReportModal";
@import "components/contactsModal";
@import "components/formalReportPage";
@import "components/tooltip";
@import "components/filesModal";
@import "components/pageNotFound";
@import "components/contextMenu";
@import "components/inputs";
@import "components/modaOverlay";
@import "components/activitiesCounts";
@import "components/addTicketModal";
@import "components/loaderProgress";
@import "components/modalContent";

@import "components/techsMap";

@import "pages/home";
@import "pages/technicians";
@import "pages/technical";
@import "pages/tickets";
@import "pages/ticket";
@import "pages/login";
@import "pages/profile";
@import "pages/forget";
@import "pages/companies";
@import "pages/register";

@import "pages/controlPanel/controlPanel";
@import "pages/controlPanel/categories";

* {
  // font-family: "Medium";
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $whiteDA;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba($color: $blue, $alpha: 0.8);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $blue;
  }
}

body {
  background-color: $lightGrey;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@import "./darkMode/darkMode";
