.filesTable {
  .ant-table {
    min-height: 60vh;
    background-color: transparent !important;
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        white-space: nowrap;
      }
    }
  }
  .ticketImg {
    border-radius: 8px;
    width: 45px;
    height: 45px;
    background-color: $gray_2;
    display: block;
    margin-inline: auto;
    object-fit: contain;
  }
  .statusBtn {
    border-radius: 18px;
    padding: 6px 10px;
    color: $white;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    @media (max-width: 992px) {
      padding: 6px 20px;
    }
    &.status1 {
      background-color: $pending;
    }
    &.status9 {
      background-color: $priced;
    }
    &.status10 {
      background-color: $paid;
    }
    &.status11 {
      background-color: $partial_paid;
    }
    &.status2 {
      background-color: $active;
    }
    &.status3 {
      background-color: $done;
    }
    &.status4 {
      background-color: $approved;
    }
    &.status5 {
      background-color: $denied;
    }
  }
  .ant-table-row {
    position: relative;
  }
  .ant-table-cell {
    text-align: center;
    background-color: transparent;
    border: none !important;
    position: initial;
    div {
      z-index: 20;
      position: relative;
    }
    .bgFaded {
      width: 100%;
      height: 100%;
      background-color: rgba($color: $blue_2, $alpha: 0.3);
      border-radius: 12px;
      position: absolute;
      opacity: 0.5;
      top: 0;
      left: 0;
      // animation: 1.5s Faded infinite both;
    }
    // @keyframes Faded {
    //   0% {
    //     background-color: rgba($color: $blue_2, $alpha: 0.3);
    //   }

    //   50% {
    //     background-color: rgba($color: $blue_2, $alpha: 0.1);
    //   }
    //   100% {
    //     background-color: rgba($color: $blue_2, $alpha: 0.3);
    //   }
    // }
    &::before {
      display: none;
    }
  }
  .ant-table-cell {
    @media (max-width: 992px) {
      padding: 0 !important;
    }
  }
  .ant-table-thead {
    @media (max-width: 992px) {
      display: none;
    }
    .ant-table-cell {
      background-color: $grayB !important;
      overflow: hidden !important;

      &:first-child {
        border-radius: 0 12px 12px 0;
      }
      &:last-child {
        border-radius: 12px 0 0 12px;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-family: Light;
    }
  }
  .fileURL {
    .disabled {
      opacity: 0.6;
      cursor: not-allowed;
      span.seeTicket {
        background-color: $gray_2;
      }
    }
  }
  .mobileCard {
    border: 1px solid $whiteE;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: $whiteE;
    .label {
      margin-bottom: 5px;
      font-size: 13px;
      text-align: right;
      span {
        margin-left: 10px;
      }
    }
  }
}
