.context-menu {
  direction: ltr;
  font-size: 14px;
  background-color: $white;
  border-radius: 10px;
  padding: 10px 5px;
  width: 150px;
  height: auto;
  margin: 0;
  z-index: 99999;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
}
