.whiteBox {
  background-color: $white;
  height: 100%;
  width: 100%;
  // border-radius: 30px;
  padding: 0px 30px 30px 30px;
  // box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.1);
  min-height: 79vh;
  overflow: hidden;
  position: relative;

  @media (max-width: 992px) {
    // padding: 30px 15px;
  }
  &.dark {
    background-color: $Grey;
  }
}
