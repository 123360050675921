.forgetPassword {
  * {
    font-family: "Medium";
  }
  .registerFormCon {
    border-radius: 30px;
    background-color: rgba($color: $white, $alpha: 0.7);
    min-height: 90vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding: 40px 20px;
    .registerInput,
    .uplaodBtn {
      border-radius: 25px;
      margin-bottom: 10px;
      padding: 10px 20px !important;
      border: none;
      box-shadow: none;
      display: block;
      width: 100%;
      height: auto;
      text-align: left;
      direction: ltr;
      border: 1px solid $grayD !important;
      &:focus {
        box-shadow: none;
        outline: none;
      }
      .anticon-upload {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }

    .submitBtn {
      width: 100%;
      background-color: black;
      background: $blue;
      text-align: center;
      border-radius: 30px;
      border: none;
      padding: 10px 0;
      color: white;
      margin-top: 10px;
      span {
        margin-inline: 5px;
      }
    }
    .login {
      width: 100%;
      background-color: transparent;
      text-align: center;
      border: none;
      padding: 10px 0;
      color: $purple;
      margin-top: 10px;
      font-size: 12px;
    }
    .historyBtn {
      color: $blue_2;
      cursor: pointer;
      font-family: "Light";
      display: "flex";
      align-items: "center";
      gap: 3px;

      @media (max-width: 992px) {
        display: block;
      }
      margin-bottom: 10px;
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-left: 7px;
      }
    }
  }
  .ant-form-item-label {
    // text-align: right;
    .ant-form-item-required {
      &::before {
        content: initial !important;
      }
    }
  }
  .ant-input-affix-wrapper {
    background-color: $whiteE;
    padding: 10px 30px 15px 10px;
    border-radius: 18px;
  }
  .ant-input {
  }
  .ant-form-item {
    margin-bottom: 15px;
  }
  input:focus {
    outline: none;
  }
}
