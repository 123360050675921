.modalMap {
  direction: rtl;
  width: fit-content;
  .username {
    font-size: 14px;
  }
  .timeLabel {
    font-size: 15px;
    span {
      display: block;
      text-align: right;
    }
  }
}
.gm-style-iw,
.gm-style-iw-d,
.gm-style-iw-c {
  padding: 0 !important;
  overflow: hidden !important;
  max-width: auto !important;
}

.gm-style .gm-style-iw {
  max-width: initial !important;
  padding: 10px !important;
}
