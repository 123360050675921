.register {
  * {
    font-family: "Medium";
  }
  .registerFormCon {
    border-radius: 30px;
    background-color: rgba($color: $white, $alpha: 0.7);
    min-height: 90vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding: 40px 20px;

    input.ant-input {
      padding: 10px 20px;
      border-radius: 25px;
    }
    .registerInput,
    .uplaodBtn {
      border-radius: 25px;
      // margin-bottom: 10px;
      padding: 10px 20px !important;
      border: none;
      box-shadow: none;
      // display: block;
      width: 100%;
      height: auto;
      text-align: left;
      direction: ltr;
      border: 1px solid $grayD;

      input.ant-input {
        background-color: $white;
        padding: 0px 20px;
      }
      input.ant-input:-webkit-autofill,
      input.ant-input:-webkit-autofill:hover,
      input.ant-input:-webkit-autofill:focus,
      input.ant-input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $white inset !important;
      }
      * {
        text-align: left;
        direction: ltr;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
      .anticon-upload {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }

    .submitBtn {
      width: 100%;
      background-color: black;
      background: $blue;
      text-align: center;
      border-radius: 30px;
      border: none;
      padding: 10px 0;
      color: white;
      margin-top: 10px;
      span {
        margin-inline: 5px;
      }
      &[disabled] {
        background: $lightGrey !important;
      }
    }
    .login {
      width: 100%;
      background-color: transparent;
      text-align: center;
      border: none;
      padding: 10px 0;
      color: $purple;
      margin-top: 10px;
      font-size: 12px;
    }
    .forgetLink {
      font-size: 13px;
      margin: 10px;
      color: $blue;
      cursor: pointer;
    }
    .ant-select-single,
    .ant-select-multiple {
      text-align: left;
      direction: ltr;
      // margin-bottom: 10px;
      width: 100%;
      box-shadow: none !important;
      .ant-select-selector {
        border: 1px solid $grayD;
        border-radius: 25px;
        box-shadow: none !important;
        padding: 5px 20px;
        height: auto;
        color: rgba($color: $black, $alpha: 0.6);
        .ant-select-selection-search {
          color: rgba($color: $black, $alpha: 0.6);

          input {
            color: rgba($color: $black, $alpha: 0.6);
          }
        }
        .ant-select-selection-item {
          color: rgba($color: $black, $alpha: 0.6);
        }
      }
    }
    .historyBtn {
      color: $blue_2;
      cursor: pointer;
      font-family: "Light";
      display: "flex";
      align-items: "center";
      gap: 3px;

      @media (max-width: 992px) {
        display: block;
      }
      margin-bottom: 10px;
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-left: 7px;
      }
    }
  }
}
